.wrapper form {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.wrapper form label {
  display: block;
}

::-webkit-input-placeholder {
  font-family: sofia-pro, sans-serif;
}

::-moz-placeholder {
  font-family: sofia-pro, sans-serif;
}

:-ms-input-placeholder {
  font-family: sofia-pro, sans-serif;
}

::placeholder {
  font-family: sofia-pro, sans-serif;
}

.wrapper form p {
  margin-top: 0;
}

.flex {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}

.wrapper form .half1 {
  flex: 50%;
  padding-right: 15px;
}

.wrapper form .half2 {
  flex: 50%;
}

.wrapper form .full {
  flex: 100%;
}

.wrapper form input,
textarea {
  /* padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #ebe8e8; */
  box-sizing: border-box;
  border-style: none;
  width: 100%;
  border: solid 1px #dbdbdb;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 15px 25px;
}

.wrapper form input,
textarea:focus {
  /* background-color: rgb(211, 207, 207); */
  outline: none;
}

.wrapper form button {
  /* padding: 12px 20px;
  background-color: rgb(253, 2, 86);
  border-style: none;
  border-radius: 4px;
  color: #fff; */

  background-color: #4d90fe;
  border: 1px solid #4d90fe;
  color: #fff;
  margin-right: 10px;
  min-width: 160px;
  padding: 15px 30px;
  transition: all ease-in-out 0.3s;
  text-transform: uppercase;
  font-family: sofia-pro, sans-serif;
}

.wrapper form button:hover {
  background-color: #3b82f5;
  outline: none;
  transition: all ease-in-out 0.3s;
}

@media screen and (max-width: 600px) {
  .wrapper form .half1 {
    flex: 100%;
    padding-right: 0;
  }

  .wrapper form .half2 {
    flex: 100%;
  }
}
