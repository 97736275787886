@font-face {
  font-family: 'Avenir Light';
  src: url(./assets/font/light.otf);
}

@font-face {
  font-family: 'Avenir Book';
  src: url(./assets/font/book.otf);
}

@font-face {
  font-family: 'Avenir Medium';
  src: url(./assets/font/medium.otf);
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url(./assets/font/heavy.ttf);
}

@font-face {
  font-family: 'Avenir Roman';
  src: url(./assets/font/roman.otf);
}

@font-face {
  font-family: 'Avenir Black';
  src: url(./assets/font/black.otf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: normal !important;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-size: 1.4rem;
  /* background-color: #fafafa; */
}

header,
footer {
  background-color: white;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #177be2;
}

/* .slick-prev {
  left: 0 !important;
  z-index: 10;
} */

button {
  cursor: pointer;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
  z-index: 2;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  z-index: 2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fd3b90;
  border-radius: 10px;
  z-index: 2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fd3b90;
}

/* Notification */
.notification {
  background-color: #177be2 !important;
  color: #fff;
}

.notification-bar {
  background: #fff;
}

.Toastify__close-button--default {
  color: #fff !important;
}

.pagination {
  width: 500px;
  display: flex;
  justify-content: center;
  margin: 4rem auto;
}

.pagination li {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-family: 'Avenir Book';
}

.pagination .active {
  background-color: #177be2;
}

.pagination .active a {
  color: #fff;
}

.pagination .end {
  display: none;
}

.pagination .disabled {
  opacity: 0.4;
}
