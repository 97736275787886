@import url(https://p.typekit.net/p.css?s=1&k=vja0nps&ht=tk&f=24553.24554.24558.24559.24537.24539.24540.24547.24548.24580.24581.24588.24589.36601.36602.36607.36608.36617.36618.36623.36624.36633.36634.36639.36640.36650&a=36733717&app=typekit&e=css);
.notification {
  background-color: #e74c3c !important;
}

@font-face {
  font-family: 'Avenir Light';
  src: url(/static/media/light.0b78ec9d.otf);
}

@font-face {
  font-family: 'Avenir Book';
  src: url(/static/media/book.ecb0c2ae.otf);
}

@font-face {
  font-family: 'Avenir Medium';
  src: url(/static/media/medium.4f995fa4.otf);
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url(/static/media/heavy.fec4972e.ttf);
}

@font-face {
  font-family: 'Avenir Roman';
  src: url(/static/media/roman.b1d7c6e0.otf);
}

@font-face {
  font-family: 'Avenir Black';
  src: url(/static/media/black.d751de38.otf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: normal !important;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-size: 1.4rem;
  /* background-color: #fafafa; */
}

header,
footer {
  background-color: white;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #177be2;
}

/* .slick-prev {
  left: 0 !important;
  z-index: 10;
} */

button {
  cursor: pointer;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
  z-index: 2;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  z-index: 2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fd3b90;
  border-radius: 10px;
  z-index: 2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fd3b90;
}

/* Notification */
.notification {
  background-color: #177be2 !important;
  color: #fff;
}

.notification-bar {
  background: #fff;
}

.Toastify__close-button--default {
  color: #fff !important;
}

.pagination {
  width: 500px;
  display: flex;
  justify-content: center;
  margin: 4rem auto;
}

.pagination li {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-family: 'Avenir Book';
}

.pagination .active {
  background-color: #177be2;
}

.pagination .active a {
  color: #fff;
}

.pagination .end {
  display: none;
}

.pagination .disabled {
  opacity: 0.4;
}

.wrapper form {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.wrapper form label {
  display: block;
}

::-webkit-input-placeholder {
  font-family: sofia-pro, sans-serif;
}

:-ms-input-placeholder {
  font-family: sofia-pro, sans-serif;
}

::-ms-input-placeholder {
  font-family: sofia-pro, sans-serif;
}

::placeholder {
  font-family: sofia-pro, sans-serif;
}

.wrapper form p {
  margin-top: 0;
}

.flex {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}

.wrapper form .half1 {
  flex: 50% 1;
  padding-right: 15px;
}

.wrapper form .half2 {
  flex: 50% 1;
}

.wrapper form .full {
  flex: 100% 1;
}

.wrapper form input,
textarea {
  /* padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #ebe8e8; */
  box-sizing: border-box;
  border-style: none;
  width: 100%;
  border: solid 1px #dbdbdb;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 15px 25px;
}

.wrapper form input,
textarea:focus {
  /* background-color: rgb(211, 207, 207); */
  outline: none;
}

.wrapper form button {
  /* padding: 12px 20px;
  background-color: rgb(253, 2, 86);
  border-style: none;
  border-radius: 4px;
  color: #fff; */

  background-color: #4d90fe;
  border: 1px solid #4d90fe;
  color: #fff;
  margin-right: 10px;
  min-width: 160px;
  padding: 15px 30px;
  transition: all ease-in-out 0.3s;
  text-transform: uppercase;
  font-family: sofia-pro, sans-serif;
}

.wrapper form button:hover {
  background-color: #3b82f5;
  outline: none;
  transition: all ease-in-out 0.3s;
}

@media screen and (max-width: 600px) {
  .wrapper form .half1 {
    flex: 100% 1;
    padding-right: 0;
  }

  .wrapper form .half2 {
    flex: 100% 1;
  }
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * sofia-pro:
 *   - http://typekit.com/eulas/00000000000000003b9b46f5
 *   - http://typekit.com/eulas/00000000000000003b9b46f3
 *   - http://typekit.com/eulas/00000000000000003b9b46f4
 *   - http://typekit.com/eulas/00000000000000003b9b46ed
 *   - http://typekit.com/eulas/00000000000000003b9b46ee
 * sofia-pro-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9b4703
 *   - http://typekit.com/eulas/00000000000000003b9b4704
 *   - http://typekit.com/eulas/00000000000000003b9b46fd
 *   - http://typekit.com/eulas/00000000000000003b9b46fe
 * sofia-pro-soft:
 *   - http://typekit.com/eulas/0000000000000000000157b7
 *   - http://typekit.com/eulas/0000000000000000000157b8
 *   - http://typekit.com/eulas/0000000000000000000157bc
 *   - http://typekit.com/eulas/0000000000000000000157bd
 * urw-din:
 *   - http://typekit.com/eulas/00000000000000003b9b0ee3
 *   - http://typekit.com/eulas/00000000000000003b9b0ee4
 *   - http://typekit.com/eulas/00000000000000003b9b0ee9
 *   - http://typekit.com/eulas/00000000000000003b9b0eea
 * urw-din-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9b0ef3
 *   - http://typekit.com/eulas/00000000000000003b9b0ef4
 *   - http://typekit.com/eulas/00000000000000003b9b0ef9
 *   - http://typekit.com/eulas/00000000000000003b9b0efa
 * urw-din-semi-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9b0f03
 *   - http://typekit.com/eulas/00000000000000003b9b0f04
 *   - http://typekit.com/eulas/00000000000000003b9b0f09
 *   - http://typekit.com/eulas/00000000000000003b9b0f0a
 * urw-form:
 *   - http://typekit.com/eulas/00000000000000003b9b0f14
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */

@font-face {
  font-family: 'sofia-pro-soft';
  src: url('https://use.typekit.net/af/9b5307/0000000000000000000157b7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9b5307/0000000000000000000157b7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9b5307/0000000000000000000157b7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'sofia-pro-soft';
  src: url('https://use.typekit.net/af/2c3915/0000000000000000000157b8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2c3915/0000000000000000000157b8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2c3915/0000000000000000000157b8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'sofia-pro-soft';
  src: url('https://use.typekit.net/af/5c3c84/0000000000000000000157bc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5c3c84/0000000000000000000157bc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5c3c84/0000000000000000000157bc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'sofia-pro-soft';
  src: url('https://use.typekit.net/af/ef7efe/0000000000000000000157bd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/ef7efe/0000000000000000000157bd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ef7efe/0000000000000000000157bd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'sofia-pro';
  src: url('https://use.typekit.net/af/b4b708/00000000000000003b9b46f5/27/l?subset_id=2&fvd=n9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b4b708/00000000000000003b9b46f5/27/d?subset_id=2&fvd=n9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b4b708/00000000000000003b9b46f5/27/a?subset_id=2&fvd=n9&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'sofia-pro';
  src: url('https://use.typekit.net/af/02ad94/00000000000000003b9b46f3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/02ad94/00000000000000003b9b46f3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/02ad94/00000000000000003b9b46f3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'sofia-pro';
  src: url('https://use.typekit.net/af/ed85d3/00000000000000003b9b46f4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/ed85d3/00000000000000003b9b46f4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ed85d3/00000000000000003b9b46f4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'sofia-pro';
  src: url('https://use.typekit.net/af/4cc789/00000000000000003b9b46ed/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4cc789/00000000000000003b9b46ed/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4cc789/00000000000000003b9b46ed/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'sofia-pro';
  src: url('https://use.typekit.net/af/964ed8/00000000000000003b9b46ee/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/964ed8/00000000000000003b9b46ee/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/964ed8/00000000000000003b9b46ee/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'sofia-pro-condensed';
  src: url('https://use.typekit.net/af/b45d8b/00000000000000003b9b4703/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b45d8b/00000000000000003b9b4703/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b45d8b/00000000000000003b9b4703/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'sofia-pro-condensed';
  src: url('https://use.typekit.net/af/67eb09/00000000000000003b9b4704/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/67eb09/00000000000000003b9b4704/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/67eb09/00000000000000003b9b4704/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'sofia-pro-condensed';
  src: url('https://use.typekit.net/af/273723/00000000000000003b9b46fd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/273723/00000000000000003b9b46fd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/273723/00000000000000003b9b46fd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'sofia-pro-condensed';
  src: url('https://use.typekit.net/af/c3f805/00000000000000003b9b46fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c3f805/00000000000000003b9b46fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c3f805/00000000000000003b9b46fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din';
  src: url('https://use.typekit.net/af/570b14/00000000000000003b9b0ee3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/570b14/00000000000000003b9b0ee3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/570b14/00000000000000003b9b0ee3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din';
  src: url('https://use.typekit.net/af/3bd84d/00000000000000003b9b0ee4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/3bd84d/00000000000000003b9b0ee4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/3bd84d/00000000000000003b9b0ee4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din';
  src: url('https://use.typekit.net/af/c196c0/00000000000000003b9b0ee9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c196c0/00000000000000003b9b0ee9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c196c0/00000000000000003b9b0ee9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'urw-din';
  src: url('https://use.typekit.net/af/359d41/00000000000000003b9b0eea/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/359d41/00000000000000003b9b0eea/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/359d41/00000000000000003b9b0eea/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'urw-din-condensed';
  src: url('https://use.typekit.net/af/f8ae76/00000000000000003b9b0ef3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f8ae76/00000000000000003b9b0ef3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f8ae76/00000000000000003b9b0ef3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din-condensed';
  src: url('https://use.typekit.net/af/dc81c6/00000000000000003b9b0ef4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/dc81c6/00000000000000003b9b0ef4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/dc81c6/00000000000000003b9b0ef4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din-condensed';
  src: url('https://use.typekit.net/af/d3af1d/00000000000000003b9b0ef9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d3af1d/00000000000000003b9b0ef9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d3af1d/00000000000000003b9b0ef9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'urw-din-condensed';
  src: url('https://use.typekit.net/af/a2b1ca/00000000000000003b9b0efa/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a2b1ca/00000000000000003b9b0efa/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a2b1ca/00000000000000003b9b0efa/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'urw-din-semi-condensed';
  src: url('https://use.typekit.net/af/d1b9a7/00000000000000003b9b0f03/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d1b9a7/00000000000000003b9b0f03/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d1b9a7/00000000000000003b9b0f03/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din-semi-condensed';
  src: url('https://use.typekit.net/af/624d03/00000000000000003b9b0f04/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/624d03/00000000000000003b9b0f04/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/624d03/00000000000000003b9b0f04/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'urw-din-semi-condensed';
  src: url('https://use.typekit.net/af/b6a996/00000000000000003b9b0f09/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b6a996/00000000000000003b9b0f09/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b6a996/00000000000000003b9b0f09/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'urw-din-semi-condensed';
  src: url('https://use.typekit.net/af/bf5c8f/00000000000000003b9b0f0a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/bf5c8f/00000000000000003b9b0f0a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/bf5c8f/00000000000000003b9b0f0a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'urw-form';
  src: url('https://use.typekit.net/af/12c22c/00000000000000003b9b0f14/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/12c22c/00000000000000003b9b0f14/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/12c22c/00000000000000003b9b0f14/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.tk-sofia-pro-soft {
  font-family: 'sofia-pro-soft', sans-serif;
}
.tk-sofia-pro {
  font-family: 'sofia-pro', sans-serif;
}
.tk-sofia-pro-condensed {
  font-family: 'sofia-pro-condensed', sans-serif;
}
.tk-urw-din {
  font-family: 'urw-din', sans-serif;
}
.tk-urw-din-condensed {
  font-family: 'urw-din-condensed', sans-serif;
}
.tk-urw-din-semi-condensed {
  font-family: 'urw-din-semi-condensed', sans-serif;
}
.tk-urw-form {
  font-family: 'urw-form', sans-serif;
}

